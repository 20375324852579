// VARIÁVEIS GLOBAIS QUE PODEM SER ACESSADAS EM TODO PROJETO
import { Injectable } from '@angular/core';
import { Setup } from '../../configuracoes/setup/setup.model';
import { Funcionalidade } from '../../seguranca/permissao/funcionalidade.model';
import { Modulo } from '../../seguranca/permissao/modulo.model';
import { Permissao } from '../../seguranca/permissao/permissao.model';
import { UsuarioLogado } from '../../seguranca/usuario/usuario-logado.model';


@Injectable()
export class GlobalsVariablesService {
    permissao = Object();
    model: Permissao;
    usuarioLogado = Object();
    isPermissao: Boolean = false;
    setup: Setup = new Setup();




    public setGlobalsVariables(usuarioLogado: UsuarioLogado) {
        //DADOS DO USUÁRIO
        this.usuarioLogado = usuarioLogado;
        //DADOS DE PERMISSÃO DO USUÁRIO
        this.model = this.usuarioLogado.permissao;
        let modulo: Array<Modulo> = this.model.modulo;
        for (var i = 0; i < modulo.length; i++) {
            let funcionalidade: Array<Funcionalidade> = modulo[i].funcionalidade;
             
            if (modulo[i].nome == "dashboard"){
               this.permissao.visualizarModuloDashboard = modulo[i].visualizar;
            }
            
            if (modulo[i].nome == "membresia"){
              this.permissao.visualizarModuloMembresia = modulo[i].visualizar;
            }
            
            if (modulo[i].nome == "financeiro"){
              this.permissao.visualizarModuloFinanceiro = modulo[i].visualizar;
            }

            if (modulo[i].nome == "seguranca"){
              this.permissao.visualizarModuloSeguranca = modulo[i].visualizar;
            }

            if (modulo[i].nome == "relatoriosMembresia"){
              this.permissao.visualizarModuloRelatoriosMembresia = modulo[i].visualizar;
            }

            if (modulo[i].nome == "relatoriosFinanceiro"){
              this.permissao.visualizarModuloRelatoriosFinanceiro = modulo[i].visualizar;
            }

            if (modulo[i].nome == "configuracoes"){
              this.permissao.visualizarModuloConfiguracoes = modulo[i].visualizar;
            }

            for (var j = 0; j < funcionalidade.length; j++) {
                let funcionalidadeNome: String = funcionalidade[j].nome;

                // -----------------------------------------   Administrativo -------------------------------------------//
                if (funcionalidadeNome == "dashboard") {
                  this.permissao.visualizarDashboard = funcionalidade[j].visualizar;
                  this.permissao.alterarDashboard = funcionalidade[j].alterar;
                  this.permissao.salvarDashboard = funcionalidade[j].incluir;
                  this.permissao.excluirDashboard = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "igreja") {
                    this.permissao.visualizarIgreja = funcionalidade[j].visualizar;
                    this.permissao.alterarIgreja = funcionalidade[j].alterar;
                    this.permissao.salvarIgreja = funcionalidade[j].incluir;
                    this.permissao.excluirIgreja = funcionalidade[j].excluir;

                }

                if (funcionalidadeNome == "faixaEtaria") {
                  this.permissao.visualizarFaixaEtaria = funcionalidade[j].visualizar;
                  this.permissao.salvarFaixaEtaria     = funcionalidade[j].incluir;
                  this.permissao.alterarFaixaEtaria    = funcionalidade[j].alterar;
                  this.permissao.excluirFaixaEtaria    = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "profissao") {
                  this.permissao.visualizarProfissao = funcionalidade[j].visualizar;
                  this.permissao.salvarProfissao = funcionalidade[j].incluir;
                  this.permissao.alterarProfissao = funcionalidade[j].alterar;
                  this.permissao.excluirProfissao = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "ministerio") {
                  this.permissao.visualizarMinisterio = funcionalidade[j].visualizar;
                  this.permissao.alterarMinisterio    = funcionalidade[j].alterar;
                  this.permissao.salvarMinisterio     = funcionalidade[j].incluir;
                  this.permissao.excluirMinisterio    = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "grupo") {
                  this.permissao.visualizarGrupo      = funcionalidade[j].visualizar;
                  this.permissao.salvarGrupo          = funcionalidade[j].incluir;
                  this.permissao.alterarGrupo         = funcionalidade[j].alterar;
                  this.permissao.excluirGrupo         = funcionalidade[j].excluir;
              }


              if (funcionalidadeNome == "rede") {
                this.permissao.visualizarRede      = funcionalidade[j].visualizar;
                this.permissao.salvarRede          = funcionalidade[j].incluir;
                this.permissao.alterarRede         = funcionalidade[j].alterar;
                this.permissao.excluirRede         = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "discipulado") {
                this.permissao.visualizarDiscipulado      = funcionalidade[j].visualizar;
                this.permissao.salvarDiscipulado          = funcionalidade[j].incluir;
                this.permissao.alterarDiscipulado         = funcionalidade[j].alterar;
                this.permissao.excluirDiscipulado         = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "celula") {
                  this.permissao.visualizarCelula           = funcionalidade[j].visualizar;
                  this.permissao.salvarCelula               = funcionalidade[j].incluir;
                  this.permissao.alterarCelula              = funcionalidade[j].alterar;
                  this.permissao.excluirCelula              = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "funcaoCelula") {
                this.permissao.visualizarFuncaoCelula     = funcionalidade[j].visualizar;
                this.permissao.salvarFuncaoCelula         = funcionalidade[j].incluir;
                this.permissao.alterarFuncaoCelula        = funcionalidade[j].alterar;
                this.permissao.excluirFuncaoCelula        = funcionalidade[j].excluir;
               }

               if (funcionalidadeNome == "cargo") {
                this.permissao.visualizarCargo      = funcionalidade[j].visualizar;
                this.permissao.salvarCargo          = funcionalidade[j].incluir;
                this.permissao.alterarCargo         = funcionalidade[j].alterar;
                this.permissao.excluirCargo         = funcionalidade[j].excluir;
               }

               if (funcionalidadeNome == "membro") {
                    this.permissao.visualizarMembro = funcionalidade[j].visualizar;
                    this.permissao.alterarMembro    = funcionalidade[j].alterar;
                    this.permissao.salvarMembro     = funcionalidade[j].incluir;
                    this.permissao.excluirMembro    = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "ocorrencia") {
                  this.permissao.visualizarOcorrencia = funcionalidade[j].visualizar;
                  this.permissao.alterarOcorrencia    = funcionalidade[j].alterar;
                  this.permissao.salvarOcorrencia     = funcionalidade[j].incluir;
                  this.permissao.excluirOcorrencia    = funcionalidade[j].excluir;
                }

                // ------------------------------------------Financeiro ----------------------------------------------------//


                if (funcionalidadeNome == "fornecedor") {
                  this.permissao.visualizarFornecedor = funcionalidade[j].visualizar;
                  this.permissao.salvarFornecedor     = funcionalidade[j].incluir;
                  this.permissao.alteraFornecedor     = funcionalidade[j].alterar;
                  this.permissao.excluirGrupo         = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "formaPagamento") {
                this.permissao.visualizarFormaPagamento = funcionalidade[j].visualizar;
                this.permissao.salvarFormaPagamento     = funcionalidade[j].incluir;
                this.permissao.alteraFormaPagamento     = funcionalidade[j].alterar;
                this.permissao.excluirFormaPagamento    = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "prazo") {
                this.permissao.visualizarPrazo = funcionalidade[j].visualizar;
                this.permissao.salvarPrazo = funcionalidade[j].incluir;
                this.permissao.alterarPrazo = funcionalidade[j].alterar;
                this.permissao.excluirPrazo = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "formaRecebimento") {
                this.permissao.visualizarFormaRecebimento = funcionalidade[j].visualizar;
                this.permissao.salvarFormaRecebimento     = funcionalidade[j].incluir;
                this.permissao.alterarFormaRecebimento    = funcionalidade[j].alterar;
                this.permissao.excluirFormaRecebimento    = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "banco") {
                this.permissao.visualizarBanco = funcionalidade[j].visualizar;
                this.permissao.salvarBanco = funcionalidade[j].incluir;
                this.permissao.alterarBanco = funcionalidade[j].alterar;
                this.permissao.excluirBanco = funcionalidade[j].excluir;
             }

              if (funcionalidadeNome == "planoContas") {
                    this.permissao.visualizarPlanoContas = funcionalidade[j].visualizar;
                    this.permissao.salvarPlanoContas = funcionalidade[j].incluir;
                    this.permissao.alterarPlanoContas = funcionalidade[j].alterar;
                    this.permissao.excluirPlanoContas = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "contaLancamento") {
                    this.permissao.visualizarContaLancamento = funcionalidade[j].visualizar;
                    this.permissao.salvarContaLancamento = funcionalidade[j].incluir;
                    this.permissao.alterarContaLancamento = funcionalidade[j].alterar;
                    this.permissao.excluirContaLancamento = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "duplicataPagar") {
                  this.permissao.visualizarDuplicataPagar = funcionalidade[j].visualizar;
                  this.permissao.salvarDuplicataPagar = funcionalidade[j].incluir;
                  this.permissao.alterarDuplicataPagar = funcionalidade[j].alterar;
                  this.permissao.excluirDuplicataPagar = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "baixaParcelaPagar") {
                  this.permissao.visualizarBaixaParcelaPagar = funcionalidade[j].visualizar;
                  this.permissao.salvarBaixaParcelaPagar = funcionalidade[j].incluir;
                  this.permissao.alterarBaixaParcelaPagar = funcionalidade[j].alterar;
                  this.permissao.excluirBaixaParcelaPagar = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "duplicataReceber") {
                this.permissao.visualizarDuplicataReceber = funcionalidade[j].visualizar;
                this.permissao.salvarDuplicataReceber = funcionalidade[j].incluir;
                this.permissao.alterarDuplicataReceber = funcionalidade[j].alterar;
                this.permissao.excluirDuplicataReceber = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "baixaParcelaReceber") {
                  this.permissao.visualizarBaixaParcelaReceber = funcionalidade[j].visualizar;
                  this.permissao.salvarBaixaParcelaReceber = funcionalidade[j].incluir;
                  this.permissao.alterarBaixaParcelaReceber = funcionalidade[j].alterar;
                  this.permissao.excluirBaixaParcelaReceber = funcionalidade[j].excluir;
              }


                if (funcionalidadeNome == "lancamentoFinanceiro") {
                    this.permissao.visualizarLancamentoFinanceiro = funcionalidade[j].visualizar;
                    this.permissao.salvarLancamentoFinanceiro = funcionalidade[j].incluir;
                    this.permissao.alterarLancamentoFinanceiro = funcionalidade[j].alterar;
                    this.permissao.excluirLancamentoFinanceiro = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "transferenciaContas") {
                  this.permissao.visualizarTransferenciaContas = funcionalidade[j].visualizar;
                  this.permissao.salvarTransferenciaContas = funcionalidade[j].incluir;
                  this.permissao.alterarTransferenciaContas = funcionalidade[j].alterar;
                  this.permissao.excluirTransferenciaContas = funcionalidade[j].excluir;
              }


                if (funcionalidadeNome == "dizimos") {
                  this.permissao.visualizarDizimos                  = funcionalidade[j].visualizar;
                  this.permissao.salvarDizimos                      = funcionalidade[j].incluir;
                  this.permissao.alterarDizimos                     = funcionalidade[j].alterar;
                  this.permissao.excluirDizimos                     = funcionalidade[j].excluir;
               }


                if (funcionalidadeNome == "estornoDizimos") {
                    this.permissao.visualizarEstornoDizimos = funcionalidade[j].visualizar;
                    this.permissao.salvarEstornoDizimos = funcionalidade[j].incluir;
                    this.permissao.alterarEstornoDizimos = funcionalidade[j].alterar;
                    this.permissao.excluirEstornoDizimos = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "fechamentoConta") {
                    this.permissao.visualizarFechamentoConta = funcionalidade[j].visualizar;
                    this.permissao.salvarFechamentoConta = funcionalidade[j].incluir;
                    this.permissao.alterarFechamentoConta = funcionalidade[j].alterar;
                    this.permissao.excluirFechamentoConta = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "fechamentoPlanoContas") {
                  this.permissao.visualizarFechamentoPlanoContas = funcionalidade[j].visualizar;
                  this.permissao.salvarFechamentoPlanoContas     = funcionalidade[j].incluir;
                  this.permissao.alterarFechamentoPlanoContas    = funcionalidade[j].alterar;
                  this.permissao.excluirFechamentoPlanoContas    = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "centroCustos") {
                this.permissao.visualizarCentroCustos = funcionalidade[j].visualizar;
                this.permissao.salvarCentroCustos     = funcionalidade[j].incluir;
                this.permissao.alterarCentroCustos    = funcionalidade[j].alterar;
                this.permissao.excluirCentroCustos    = funcionalidade[j].excluir;
               }

               if (funcionalidadeNome == "contaContabil") {
                this.permissao.visualizarContaContabil = funcionalidade[j].visualizar;
                this.permissao.salvarContaContabil     = funcionalidade[j].incluir;
                this.permissao.alterarContaContabil    = funcionalidade[j].alterar;
                this.permissao.excluirContaContabil    = funcionalidade[j].excluir;
               }

               if (funcionalidadeNome == "configuracaoContabil") {
                this.permissao.visualizarConfiguracaoContabil = funcionalidade[j].visualizar;
                this.permissao.salvarConfiguracaoContabil     = funcionalidade[j].incluir;
                this.permissao.alterarConfiguracaoContabil    = funcionalidade[j].alterar;
                this.permissao.excluirConfiguracaoContabil    = funcionalidade[j].excluir;
               }






                // ------------------------------------------Segurança ----------------------------------------------------//

                if (funcionalidadeNome == "permissoes") {
                    this.permissao.visualizarPermissao = funcionalidade[j].visualizar;
                    this.permissao.salvarPermissao = funcionalidade[j].incluir;
                    this.permissao.alterarPermissao = funcionalidade[j].alterar;
                    this.permissao.excluirPermissao = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "usuario") {
                    this.permissao.visualizarUsuario = funcionalidade[j].visualizar;
                    this.permissao.salvarUsuario = funcionalidade[j].incluir;
                    this.permissao.alterarUsuario = funcionalidade[j].alterar;
                    this.permissao.excluirUsuario = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "trocarSenha") {
                  this.permissao.visualizarTrocarSenha = funcionalidade[j].visualizar;
                  this.permissao.salvarTrocarSenha     = funcionalidade[j].incluir;
                  this.permissao.alterarTrocarSenha    = funcionalidade[j].alterar;
                  this.permissao.excluirTrocarSenha    = funcionalidade[j].excluir;
              }

                if (funcionalidadeNome == "grupoIgreja") {
                    this.permissao.visualizarGrupoIgreja = funcionalidade[j].visualizar;
                    this.permissao.salvarGrupoIgreja = funcionalidade[j].incluir;
                    this.permissao.alterarGrupoIgreja = funcionalidade[j].alterar;
                    this.permissao.excluirGrupoIgreja = funcionalidade[j].excluir;
                }

                 // -------------------------------------------- Relatorios ----------------------------------------------//
                if (funcionalidadeNome == "relatorioMembro") {
                    this.permissao.visualizarRelatorioMembro = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioMembro     = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioMembro    = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioMembro    = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioCelulas") {
                  this.permissao.visualizarRelatorioCelulas = funcionalidade[j].visualizar;
                  this.permissao.salvarRelatorioCelulas     = funcionalidade[j].incluir;
                  this.permissao.alterarRelatorioCelulas    = funcionalidade[j].alterar;
                  this.permissao.excluirRelatorioCelulas    = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioFichaMembro") {
                    this.permissao.visualizarRelatorioFichaMembro = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioFichaMembro     = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioFichaMembro    = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioFichaMembro    = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioAniversariante") {
                    this.permissao.visualizarRelatorioAniversariante  = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioAniversariante      = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioAniversariante     = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioAniversariante     = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioCarteirinhas") {
                    this.permissao.visualizarRelatorioCarteirinhas = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioCarteirinhas     = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioCarteirinhas    = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioCarteirinhas    = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioCertificado") {
                    this.permissao.visualizarRelatorioCertificado = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioCertificado     = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioCertificado    = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioCertificado    = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "relatorioFrequenciaSemanal") {
                  this.permissao.visualizarRelatorioFrequenciaSemanal = funcionalidade[j].visualizar;
                  this.permissao.salvarRelatorioFrequenciaSemanal     = funcionalidade[j].incluir;
                  this.permissao.alterarRelatorioFrequenciaSemanal    = funcionalidade[j].alterar;
                  this.permissao.excluirRelatorioFrequenciaSemanal    = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioFrequenciaCelula") {
                  this.permissao.visualizarRelatorioFrequenciaCelula  = funcionalidade[j].visualizar;
                  this.permissao.salvarRelatorioFrequenciaCelula      = funcionalidade[j].incluir;
                  this.permissao.alterarRelatorioFrequenciaCelula     = funcionalidade[j].alterar;
                  this.permissao.excluirRelatorioFrequenciaCelula     = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioFrequenciaDiscipulado") {
                  this.permissao.visualizarRelatorioFrequenciaDiscipulado  = funcionalidade[j].visualizar;
                  this.permissao.salvarRelatorioFrequenciaDiscipulado      = funcionalidade[j].incluir;
                  this.permissao.alterarRelatorioFrequenciaDiscipulado     = funcionalidade[j].alterar;
                  this.permissao.excluirRelatorioFrequenciaDiscipulado     = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioFrequenciaRede") {
                  this.permissao.visualizarRelatorioFrequenciaRede  = funcionalidade[j].visualizar;
                  this.permissao.salvarRelatorioFrequenciaRede      = funcionalidade[j].incluir;
                  this.permissao.alterarRelatorioFrequenciaRede     = funcionalidade[j].alterar;
                  this.permissao.excluirRelatorioFrequenciaRede     = funcionalidade[j].excluir;
                }

                /***
                 * 
                 * Colocar aqui o relatorios
                 */



               if (funcionalidadeNome == "relatorioDuplicatasReceber") {
                this.permissao.visualizarRelatorioDuplicatasReceber = funcionalidade[j].visualizar;
                this.permissao.salvarRelatorioDuplicatasReceber = funcionalidade[j].incluir;
                this.permissao.alterarRelatorioDuplicatasReceber = funcionalidade[j].alterar;
                this.permissao.excluirRelatorioDuplicatasReceber = funcionalidade[j].excluir;
               }

                if (funcionalidadeNome == "relatorioDuplicatasPagar") {
                    this.permissao.visualizarRelatorioDuplicatasPagar = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioDuplicatasPagar = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioDuplicatasPagar = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioDuplicatasPagar = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioPlanoContas") {
                    this.permissao.visualizarRelatorioPlanoContas = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioPlanoContas = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioPlanoContas = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioPlanoContas = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "relatorioLancamentoFinanceiro") {
                    this.permissao.visualizarRelatorioLancamentoFinanceiro = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioLancamentoFinanceiro = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioLancamentoFinanceiro = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioLancamentoFinanceiro = funcionalidade[j].excluir;
                }

               if (funcionalidadeNome == "relatorioDizimos") {
                    this.permissao.visualizarRelatorioDizimos  = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioDizimos      = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioDizimos     = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioDizimos     = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioMovimentacaoPlanoContas") {
                    this.permissao.visualizarRelatorioMovimentacaoPlanoContas  = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioMovimentacaoPlanoContas      = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioMovimentacaoPlanoContas     = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioMovimentacaoPlanoContas     = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioFechamentoPlanoContas") {
                  this.permissao.visualizarRelatorioFechamentoPlanoContas  = funcionalidade[j].visualizar;
                  this.permissao.salvarRelatorioFechamentoPlanoContas      = funcionalidade[j].incluir;
                  this.permissao.alterarRelatorioFechamentoPlanoContas     = funcionalidade[j].alterar;
                  this.permissao.excluirRelatorioFechamentoPlanoContas     = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "gerarArquivo") {
                    this.permissao.visualizarGerarArquivo  = funcionalidade[j].visualizar;
                    this.permissao.salvarGerarArquivo      = funcionalidade[j].incluir;
                    this.permissao.alterarGerarArquivo     = funcionalidade[j].alterar;
                    this.permissao.excluirGerarArquivo     = funcionalidade[j].excluir;
                  }

                // -------------------------------------------- Configuracoes ----------------------------------------------//
                if (funcionalidadeNome == "importacaoArquivoXLS") {
                    this.permissao.visualizarImportacaoXLS = funcionalidade[j].visualizar;
                    this.permissao.salvarImportacaoXLS = funcionalidade[j].incluir;
                    this.permissao.alterarImportacaoXLS = funcionalidade[j].alterar;
                    this.permissao.excluirImportacaoXLS = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "setup") {
                    this.permissao.visualizarSetup = funcionalidade[j].visualizar;
                    this.permissao.salvarSetup = funcionalidade[j].incluir;
                    this.permissao.alterarSetup = funcionalidade[j].alterar;
                    this.permissao.excluirSetup = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "tipoRecebimento") {
                  this.permissao.visualizarTipoRecebimento = funcionalidade[j].visualizar;
                  this.permissao.salvarTipoRecebimento     = funcionalidade[j].incluir;
                  this.permissao.alterarTipoRecebimento    = funcionalidade[j].alterar;
                  this.permissao.excluirTipoRecebimneto    = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "configuracaoRecebimento") {
                  this.permissao.visualizarConfiguracaoRecebimento  = funcionalidade[j].visualizar;
                  this.permissao.salvarConfiguracaoRecebimento      = funcionalidade[j].incluir;
                  this.permissao.alterarConfiguracaoRecebimento     = funcionalidade[j].alterar;
                  this.permissao.excluirConfiguracaoRecebimento     = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "configuracaoDocumento") {
                  this.permissao.visualizarConfiguracaoDocumento  = funcionalidade[j].visualizar;
                  this.permissao.salvarConfiguracaoDocumento      = funcionalidade[j].incluir;
                  this.permissao.alterarConfiguracaoDocumento     = funcionalidade[j].alterar;
                  this.permissao.excluirConfiguracaoDocumento     = funcionalidade[j].excluir;
              }

                if (funcionalidadeNome == "modeloDocumentos") {
                    this.permissao.visualizarModeloDocumentos = funcionalidade[j].visualizar;
                    this.permissao.salvarModeloDocumentos     = funcionalidade[j].incluir;
                    this.permissao.alterarModeloDocumentos    = funcionalidade[j].alterar;
                    this.permissao.excluirModeloDocumentos     = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "tipoWeb") {
                  this.permissao.visualizarTipoWeb = funcionalidade[j].visualizar;
                  this.permissao.salvarTipoWeb = funcionalidade[j].incluir;
                  this.permissao.alterarTipoWeb = funcionalidade[j].alterar;
                  this.permissao.excluirTipoWeb = funcionalidade[j].excluir;
              }


              if (funcionalidadeNome == "tipoTelefone") {
                  this.permissao.visualizarTipoTelefone = funcionalidade[j].visualizar;
                  this.permissao.salvarTipoTelefone = funcionalidade[j].incluir;
                  this.permissao.alterarTipoTelefone = funcionalidade[j].alterar;
                  this.permissao.excluirTipoTelefone = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "credenciadoraCartao") {
                  this.permissao.visualizarCredenciadorCartao = funcionalidade[j].visualizar;
                  this.permissao.salvarCredenciadorCartao = funcionalidade[j].incluir;
                  this.permissao.alterarCredenciadorCartao = funcionalidade[j].alterar;
                  this.permissao.excluirCredenciadorCartao = funcionalidade[j].excluir;
              }

              if (funcionalidadeNome == "bandeiraCartao") {
                  this.permissao.visualizarBandeiraCartao = funcionalidade[j].visualizar;
                  this.permissao.salvarBandeiraCartao = funcionalidade[j].incluir;
                  this.permissao.alterarBandeiraCartao = funcionalidade[j].alterar;
                  this.permissao.excluirBandeiraCartao = funcionalidade[j].excluir;
              }


              if (funcionalidadeNome == "instrucaoBancaria") {
                this.permissao.visualizarInstrucaoBancaria = funcionalidade[j].visualizar;
                this.permissao.salvarInstrucaoBancaria = funcionalidade[j].incluir;
                this.permissao.alterarInstrucaoBancaria = funcionalidade[j].alterar;
                this.permissao.excluirInstrucaoBancaria = funcionalidade[j].excluir;
            }


            if (funcionalidadeNome == "retornoBancario") {
                this.permissao.visualizarRetornoBancario = funcionalidade[j].visualizar;
                this.permissao.salvarRetornoBancario = funcionalidade[j].incluir;
                this.permissao.alterarRetornoBancario = funcionalidade[j].alterar;
                this.permissao.excluirRetornoBancario = funcionalidade[j].excluir;
            }


            if (funcionalidadeNome == "motivoDevolucaoCheque") {
                this.permissao.visualizarMotivoDevolucaoCheque = funcionalidade[j].visualizar;
                this.permissao.salvarMotivoDevolucaoCheque = funcionalidade[j].incluir;
                this.permissao.alterarMotivoDevolucaoCheque = funcionalidade[j].alterar;
                this.permissao.excluirMotivoDevolucaoCheque = funcionalidade[j].excluir;
            }

            }
        }
        this.isPermissao = true;
    }

    get getPermissoes() {
        return this.permissao;
    }

    get getInstituicao() {
      let tipoInstituicao = "Igreja";
      if (localStorage.getItem("tipo_instituicao") === "2"){        
        tipoInstituicao = "JCI"
      }else {
        tipoInstituicao = "Igreja";
      }
      return tipoInstituicao;     
  }

    get getUsuarioLogado() {
        if (this.usuarioLogado.caminhoImagem == null) {
            this.usuarioLogado.caminhoImagem = './assets/img/logo-usuario.png';
        }
        return this.usuarioLogado;
    }

    get getPermissaoSuccess() {
        return this.isPermissao;
    }

    public blockedGlobalsVariables() {
        if (this.getPermissaoSuccess) {
            let res = false;
            //DADOS DE PERMISSÃO DO USUÁRIO
            this.isPermissao = false;
            this.usuarioLogado.token = undefined;
            this.model = this.usuarioLogado.permissao;
            let modulo: Array<Modulo> = this.model.modulo;
            for (var i = 0; i < modulo.length; i++) {
                let funcionalidade: Array<Funcionalidade> = modulo[i].funcionalidade;



                
                for (var j = 0; j < funcionalidade.length; j++) {
                    let funcionalidadeNome: String = funcionalidade[j].descricao;

              // -----------------------------------------   Administrativo -------------------------------------------//
                     // -----------------------------------------   Administrativo -------------------------------------------//
                     if (funcionalidadeNome == "dashboard") {
                      this.permissao.visualizarDashboard = res;
                      this.permissao.alterarDashboard    = res;;
                      this.permissao.salvarDashboard     = res;
                      this.permissao.excluirDashboard    = res;
                    }

                    if (funcionalidadeNome == "igreja") {
                        this.permissao.visualizarIgreja = res;
                        this.permissao.alterarIgreja = res;
                        this.permissao.salvarIgreja = res;
                        this.permissao.excluirIgreja = res;;

                    }

                    if (funcionalidadeNome == "faixaEtaria") {
                      this.permissao.visualizarFaixaEtaria = res;
                      this.permissao.salvarFaixaEtaria     = res;
                      this.permissao.alterarFaixaEtaria    = res;;
                      this.permissao.excluirFaixaEtaria    = res;
                    }

                    if (funcionalidadeNome == "profissao") {
                      this.permissao.visualizarProfissao = res;
                      this.permissao.salvarProfissao = res;
                      this.permissao.alterarProfissao = res;
                      this.permissao.excluirProfissao = res;
                    }

                    if (funcionalidadeNome == "ministerio") {
                      this.permissao.visualizarMinisterio = res;
                      this.permissao.alterarMinisterio    = res;
                      this.permissao.salvarMinisterio     = res;
                      this.permissao.excluirMinisterio    = res;
                    }

                    if (funcionalidadeNome == "grupo") {
                      this.permissao.visualizarGrupo      = res;
                      this.permissao.salvarGrupo          = res;
                      this.permissao.alterarGrupo         = res;
                      this.permissao.excluirGrupo         = res;
                  }


                  if (funcionalidadeNome == "rede") {
                    this.permissao.visualizarRede      = res;
                    this.permissao.salvarRede          = res;
                    this.permissao.alterarRede         = res;
                    this.permissao.excluirRede         = res;
                  }

                  if (funcionalidadeNome == "discipulado") {
                    this.permissao.visualizarDiscipulado      = res;
                    this.permissao.salvarDiscipulado          = res;
                    this.permissao.alterarDiscipulado         = res;
                    this.permissao.excluirDiscipulado         = res;
                  }

                  if (funcionalidadeNome == "celula") {
                      this.permissao.visualizarCelula           = res;
                      this.permissao.salvarCelula               = res;
                      this.permissao.alterarCelula              = res;
                      this.permissao.excluirCelula              = res;
                  }

                  if (funcionalidadeNome == "funcaoCelula") {
                    this.permissao.visualizarFuncaoCelula     = res;
                    this.permissao.salvarFuncaoCelula         = res;
                    this.permissao.alterarFuncaoCelula        = res;
                    this.permissao.excluirFuncaoCelula        = res;
                   }

                   if (funcionalidadeNome == "cargo") {
                    this.permissao.visualizarCargo      = res;
                    this.permissao.salvarCargo          = res;
                    this.permissao.alterarCargo         = res;
                    this.permissao.excluirCargo         = res;
                   }

                   if (funcionalidadeNome == "membro") {
                        this.permissao.visualizarMembro = res;
                        this.permissao.alterarMembro    = res;
                        this.permissao.salvarMembro     = res;
                        this.permissao.excluirMembro    = res;
                    }

                    if (funcionalidadeNome == "ocorrencia") {
                      this.permissao.visualizarOcorrencia = res;
                      this.permissao.alterarOcorrencia    = res;
                      this.permissao.salvarOcorrencia     = res;
                      this.permissao.excluirOcorrencia    = res;
                    }

                    // ------------------------------------------Financeiro ----------------------------------------------------//


                    if (funcionalidadeNome == "fornecedor") {
                      this.permissao.visualizarFornecedor = res;
                      this.permissao.salvarFornecedor     = res;
                      this.permissao.alteraFornecedor     = res;
                      this.permissao.excluirGrupo         = res;
                  }

                  if (funcionalidadeNome == "formaPagamento") {
                    this.permissao.visualizarFormaPagamento = res;
                    this.permissao.salvarFormaPagamento     = res;
                    this.permissao.alteraFormaPagamento     = res;
                    this.permissao.excluirFormaPagamento    = res;
                  }

                  if (funcionalidadeNome == "prazo") {
                    this.permissao.visualizarPrazo = res;
                    this.permissao.salvarPrazo = res;
                    this.permissao.alterarPrazo = res;
                    this.permissao.excluirPrazo = res;
                  }

                  if (funcionalidadeNome == "formaRecebimento") {
                    this.permissao.visualizarFormaRecebimento = res;
                    this.permissao.salvarFormaRecebimento     = res;
                    this.permissao.alterarFormaRecebimento    = res;;
                    this.permissao.excluirFormaRecebimento    = res;
                  }

                  if (funcionalidadeNome == "banco") {
                    this.permissao.visualizarBanco = res;
                    this.permissao.salvarBanco = res;
                    this.permissao.alterarBanco = res;
                    this.permissao.excluirBanco = res;
                 }

                  if (funcionalidadeNome == "planoContas") {
                        this.permissao.visualizarPlanoContas = res;
                        this.permissao.salvarPlanoContas = res;
                        this.permissao.alterarPlanoContas = res;
                        this.permissao.excluirPlanoContas = res;
                    }

                    if (funcionalidadeNome == "contaLancamento") {
                        this.permissao.visualizarContaLancamento = res;
                        this.permissao.salvarContaLancamento = res;
                        this.permissao.alterarContaLancamento = res;
                        this.permissao.excluirContaLancamento = res;
                    }

                    if (funcionalidadeNome == "duplicataPagar") {
                      this.permissao.visualizarDuplicataPagar = res;
                      this.permissao.salvarDuplicataPagar = res;
                      this.permissao.alterarDuplicataPagar = res;
                      this.permissao.excluirDuplicataPagar = res;
                  }

                  if (funcionalidadeNome == "baixaParcelaPagar") {
                      this.permissao.visualizarBaixaParcelaPagar = res;
                      this.permissao.salvarBaixaParcelaPagar = res;
                      this.permissao.alterarBaixaParcelaPagar  = res;
                      this.permissao.excluirBaixaParcelaPagar = res;
                  }

                  if (funcionalidadeNome == "duplicataReceber") {
                    this.permissao.visualizarDuplicataReceber = res;
                    this.permissao.salvarDuplicataReceber = res;
                    this.permissao.alterarDuplicataReceber = res;
                    this.permissao.excluirDuplicataReceber = res;
                  }

                  if (funcionalidadeNome == "baixaParcelaReceber") {
                      this.permissao.visualizarBaixaParcelaReceber = res;
                      this.permissao.salvarBaixaParcelaReceber = res;
                      this.permissao.alterarBaixaParcelaReceber = res;
                      this.permissao.excluirBaixaParcelaReceber = res;
                  }


                    if (funcionalidadeNome == "lancamentoFinanceiro") {
                        this.permissao.visualizarLancamentoFinanceiro = res;
                        this.permissao.salvarLancamentoFinanceiro = res;
                        this.permissao.alterarLancamentoFinanceiro = res;
                        this.permissao.excluirLancamentoFinanceiro = res;
                    }


                    if (funcionalidadeNome == "dizimos") {
                      this.permissao.visualizarDizimos                  = res;
                      this.permissao.salvarDizimos                      = res;
                      this.permissao.alterarDizimos                     = res;
                      this.permissao.excluirDizimos                     = res;
                   }


                    if (funcionalidadeNome == "estornoDizimos") {
                        this.permissao.visualizarEstornoDizimos = res;
                        this.permissao.salvarEstornoDizimos = res;
                        this.permissao.alterarEstornoDizimos = res;
                        this.permissao.excluirEstornoDizimos = res;
                    }


                 

                    if (funcionalidadeNome == "fechamentoPlanoContas") {
                      this.permissao.visualizarFechamentoPlanoContas = res;
                      this.permissao.salvarFechamentoPlanoContas = res;
                      this.permissao.alterarFechamentoPlanoContas = res;
                      this.permissao.excluirFechamentoPlanoContas = res;
                    }

                    if (funcionalidadeNome == "centroCustos") {
                        this.permissao.visualizarCentroCustos = res;
                        this.permissao.salvarCentroCustos = res;
                        this.permissao.alterarCentroCustos = res;
                        this.permissao.excluirCentroCustos = res;
                      }

                      if (funcionalidadeNome == "contaContabil") {
                        this.permissao.visualizarContaContabil = res;
                        this.permissao.salvarContaContabil = res;
                        this.permissao.alterarContaContabil = res;
                        this.permissao.excluirContaContabil = res;
                      }
                      if (funcionalidadeNome == "configuracaoContabil") {
                        this.permissao.visualizarConfiguracaoContabil = res;
                        this.permissao.salvarConfiguracaoContabil = res;
                        this.permissao.alterarConfiguracaoContabil = res;
                        this.permissao.excluirConfiguracaoContabil = res;
                      }


                    // ------------------------------------------Segurança ----------------------------------------------------//

                    if (funcionalidadeNome == "permissoes") {
                        this.permissao.visualizarPermissao = res;
                        this.permissao.salvarPermissao = res;
                        this.permissao.alterarPermissao = res;
                        this.permissao.excluirPermissao = res;
                    }

                    if (funcionalidadeNome == "usuario") {
                        this.permissao.visualizarUsuario = res;
                        this.permissao.salvarUsuario = res;
                        this.permissao.alterarUsuario = res;
                        this.permissao.excluirUsuario = res;
                    }

                    if (funcionalidadeNome == "trocarSenha") {
                      this.permissao.visualizarTrocarSenha = res;
                      this.permissao.salvarTrocarSenha     = res;
                      this.permissao.alterarTrocarSenha    = res;
                      this.permissao.excluirTrocarSenha    = res;
                  }

                    if (funcionalidadeNome == "grupoIgreja") {
                        this.permissao.visualizarGrupoIgreja = res;
                        this.permissao.salvarGrupoIgreja = res;
                        this.permissao.alterarGrupoIgreja = res;
                        this.permissao.excluirGrupoIgreja = res;
                    }

                     // -------------------------------------------- Relatorios ----------------------------------------------//
                    if (funcionalidadeNome == "relatorioMembro") {
                        this.permissao.visualizarRelatorioMembro = res;
                        this.permissao.salvarRelatorioMembro     = res;
                        this.permissao.alterarRelatorioMembro    = res;
                        this.permissao.excluirRelatorioMembro    = res;
                    }
                    if (funcionalidadeNome == "relatorioFichaMembro") {
                        this.permissao.visualizarRelatorioFichaMembro = res;
                        this.permissao.salvarRelatorioFichaMembro     = res;
                        this.permissao.alterarRelatorioFichaMembro    = res;
                        this.permissao.excluirRelatorioFichaMembro   = res;
                    }

                    if (funcionalidadeNome == "relatorioAniversariante") {
                        this.permissao.visualizarRelatorioAniversariante  = res;
                        this.permissao.salvarRelatorioAniversariante      = res;
                        this.permissao.alterarRelatorioAniversariante     = res;
                        this.permissao.excluirRelatorioAniversariante     = res;
                    }

                    if (funcionalidadeNome == "relatorioCarteirinhas") {
                        this.permissao.visualizarRelatorioCarteirinhas = res;
                        this.permissao.salvarRelatorioCarteirinhas     = res;
                        this.permissao.alterarRelatorioCarteirinhas    = res;
                        this.permissao.excluirRelatorioCarteirinhas    = res;
                    }

                  if (funcionalidadeNome == "relatorioDuplicatasReceber") {
                    this.permissao.visualizarRelatorioDuplicatasReceber = res;
                    this.permissao.salvarRelatorioDuplicatasReceber = res;
                    this.permissao.alterarRelatorioDuplicatasReceber = res;
                    this.permissao.excluirRelatorioDuplicatasReceber = res;
                   }

                    if (funcionalidadeNome == "relatorioDuplicatasPagar") {
                        this.permissao.visualizarRelatorioDuplicatasPagar = res;
                        this.permissao.salvarRelatorioDuplicatasPagar = res;
                        this.permissao.alterarRelatorioDuplicatasPagar = res;
                        this.permissao.excluirRelatorioDuplicatasPagar = res;
                    }

                    if (funcionalidadeNome == "relatorioPlanoContas") {
                        this.permissao.visualizarRelatorioPlanoContas = res;
                        this.permissao.salvarRelatorioPlanoContas = res;
                        this.permissao.alterarRelatorioPlanoContas = res;
                        this.permissao.excluirRelatorioPlanoContas = res;
                    }


                    if (funcionalidadeNome == "relatorioLancamentoFinanceiro") {
                        this.permissao.visualizarRelatorioLancamentoFinanceiro = res;
                        this.permissao.salvarRelatorioLancamentoFinanceiro = res;
                        this.permissao.alterarRelatorioLancamentoFinanceiro = res;
                        this.permissao.excluirRelatorioLancamentoFinanceiro = res;
                    }

                   if (funcionalidadeNome == "relatorioDizimos") {
                        this.permissao.visualizarRelatorioDizimos  = res;
                        this.permissao.salvarRelatorioDizimos      = res;
                        this.permissao.alterarRelatorioDizimos     = res;
                        this.permissao.excluirRelatorioDizimos     = res;
                    }

                    if (funcionalidadeNome == "relatorioMovimentacaoPlanoContas") {
                        this.permissao.visualizarRelatorioMovimentacaoPlanoContas  = res;
                        this.permissao.salvarRelatorioMovimentacaoPlanoContas      = res;
                        this.permissao.alterarRelatorioMovimentacaoPlanoContas     = res;
                        this.permissao.excluirRelatorioMovimentacaoPlanoContas     = res;
                    }

                    if (funcionalidadeNome == "relatorioFechamentoPlanoContas") {
                        this.permissao.visualizarRelatorioFechamentoPlanoContas  = res;
                        this.permissao.salvarRelatorioFechamentoPlanoContas      = res;
                        this.permissao.alterarRelatorioFechamentoPlanoContas     = res;
                        this.permissao.excluirRelatorioFechamentoPlanoContas     = res;
                    }

                    if (funcionalidadeNome == "gerarArquivo") {
                        this.permissao.visualizarGerarArquivo  = res;
                        this.permissao.salvarGerarArquivo      = res;
                        this.permissao.alterarGerarArquivo     = res;
                        this.permissao.excluirGerarArquivo     = res;
                    }

                    // -------------------------------------------- Configuracoes ----------------------------------------------//
                    if (funcionalidadeNome == "importacaoArquivoXLS") {
                        this.permissao.visualizarImportacaoXLS = res;
                        this.permissao.salvarImportacaoXLS = res;
                        this.permissao.alterarImportacaoXLS = res;
                        this.permissao.excluirImportacaoXLS = res;
                    }

                    if (funcionalidadeNome == "importacaoMembroArquivoXLS") {
                      this.permissao.visualizarImportacaoMembroArquivoXLS = res;
                      this.permissao.salvarImportacaoMembroArquivoXLS = res;
                      this.permissao.alterarImportacaoMembroArquivoXLS = res;
                      this.permissao.excluirImportacaoMembroArquivoXLS = res;
                    }


                    if (funcionalidadeNome == "setup") {
                        this.permissao.visualizarSetup = res;
                        this.permissao.salvarSetup = res;
                        this.permissao.alterarSetup = res;
                        this.permissao.excluirSetup = res;
                    }

                    if (funcionalidadeNome == "tipoRecebimento") {
                      this.permissao.visualizarTipoRecebimento = res;
                      this.permissao.salvarTipoRecebimento     = res;
                      this.permissao.alterarTipoRecebimento    = res;
                      this.permissao.excluirTipoRecebimneto    = res;
                  }

                  if (funcionalidadeNome == "configuracaoRecebimento") {
                      this.permissao.visualizarConfiguracaoRecebimento  = res;
                      this.permissao.salvarConfiguracaoRecebimento      = res;
                      this.permissao.alterarConfiguracaoRecebimento     = res;
                      this.permissao.excluirConfiguracaoRecebimento     = res;
                  }

                  if (funcionalidadeNome == "configuracaoDocumento") {
                      this.permissao.visualizarConfiguracaoDocumento  = res;
                      this.permissao.salvarConfiguracaoDocumento      = res;
                      this.permissao.alterarConfiguracaoDocumento     = res;
                      this.permissao.excluirConfiguracaoDocumento     = res;
                  }

                    if (funcionalidadeNome == "modeloDocumentos") {
                        this.permissao.visualizarModeloDocumentos = res;
                        this.permissao.salvarModeloDocumentos     = res;
                        this.permissao.alterarModeloDocumentos    = res;
                        this.permissao.excluirModeloDocumentos     = res;;
                    }


                    if (funcionalidadeNome == "tipoWeb") {
                      this.permissao.visualizarTipoWeb = res;
                      this.permissao.salvarTipoWeb = res;
                      this.permissao.alterarTipoWeb = res;
                      this.permissao.excluirTipoWeb = res;
                  }


                  if (funcionalidadeNome == "tipoTelefone") {
                      this.permissao.visualizarTipoTelefone = res;;
                      this.permissao.salvarTipoTelefone = res;
                      this.permissao.alterarTipoTelefone = res;
                      this.permissao.excluirTipoTelefone = res;
                  }

                  if (funcionalidadeNome == "credenciadoraCartao") {
                      this.permissao.visualizarCredenciadorCartao = res;
                      this.permissao.salvarCredenciadorCartao = res;
                      this.permissao.alterarCredenciadorCartao = res;
                      this.permissao.excluirCredenciadorCartao = res;
                  }

                  if (funcionalidadeNome == "bandeiraCartao") {
                      this.permissao.visualizarBandeiraCartao = res;
                      this.permissao.salvarBandeiraCartao = res;
                      this.permissao.alterarBandeiraCartao = res;
                      this.permissao.excluirBandeiraCartao = res;
                  }


                  if (funcionalidadeNome == "instrucaoBancaria") {
                    this.permissao.visualizarInstrucaoBancaria = res;
                    this.permissao.salvarInstrucaoBancaria = res;
                    this.permissao.alterarInstrucaoBancaria = res;
                    this.permissao.excluirInstrucaoBancaria = res;
                }


                if (funcionalidadeNome == "retornoBancario") {
                    this.permissao.visualizarRetornoBancario = res;
                    this.permissao.salvarRetornoBancario = res;
                    this.permissao.alterarRetornoBancario = res;
                    this.permissao.excluirRetornoBancario = res;
                }


                if (funcionalidadeNome == "motivoDevolucaoCheque") {
                    this.permissao.visualizarMotivoDevolucaoCheque = res;
                    this.permissao.salvarMotivoDevolucaoCheque = res;
                    this.permissao.alterarMotivoDevolucaoCheque = res;
                    this.permissao.excluirMotivoDevolucaoCheque = res;
                }

                }
            }

        }
    }

    public setInformacoesSetup(res) {
        this.setup = res;
    }

    get setupModel() {
        return this.setup;
    }

}
